import React, { useState } from 'react';
import Head from 'next/head';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  Lock,
  AccountCircle,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import getLocalizationProps from 'utils/helpers/getLocalizationProps';
import readyToValidation from 'utils/helpers/validation';
import LoginDTO from 'modules/auth/types/LoginDTO';
import { Login } from 'modules/auth/api';
import { dispatcher } from 'store/store';
import { setAppLoggedUserAction } from 'store/app-actions';
import PermissionControl from 'modules/auth/helpers/PermissionControl';
import Routes from 'routes/routes';

const StyledMainBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#0B1419',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `url(${process.env.NEXT_PUBLIC_BASE_PATH}images/light-bg.png)`,
});

const StyledOverlayImage = styled(Box)({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  backgroundSize: 'auto',
  backgroundPosition: 'center',
  backgroundImage: `url(${process.env.NEXT_PUBLIC_BASE_PATH}images/login-bg.png)`,
});

const StyledTypography = styled((props: TypographyProps) => (
  <Typography {...props} variant="h2" />
))({
  fontSize: '2.5rem',
  fontWeight: '800',
  color: '#252525',
});

const SyledFormGroup = styled(Box)({
  marginTop: 34,
  width: '100%',
});

const formControl = {
  width: '100%',
  color: '#fff',
  borderRadius: '5px',
  backgroundColor: '#252525',
  border: '1px solid rgba(251, 196, 25, 0.2)',
  boxShadow: '2px 2px 15px 1px rgba(251, 196, 25, 0.5)',
};

const StyledFormControl = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    ...formControl,
    '& input': {
      color: '#fff',
    },
    '&:hover fieldset': {
      borderColor: '#FBC419',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  '& .MuiFormHelperText-root': {
    color: 'red',
  },
});

const StyledCopyright = styled('p')({
  color: '#fff',
  position: 'absolute',
  left: 10,
  bottom: 10,
  fontSize: '1rem',
  margin: 0,
});

export default function Index() {
  const router = useRouter();
  const { t } = useTranslation(['login', 'validation']);

  const [passwordVisiblity, setPasswordVisiblity] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginDTO>();

  const handleVisibilityPassword = () =>
    setPasswordVisiblity(!passwordVisiblity);

  const onSubmit = (data: LoginDTO) => {
    Login(data).then((response) => {
      if (response.success) {
        const { userInfo } = response.result;
        dispatcher(setAppLoggedUserAction(userInfo));

        if (PermissionControl.Control(userInfo.roleNames, 'Administrator')) {
          router.push(Routes.index.path);
        }
      }
    });
  };

  const fields = {
    username: t('username'),
    password: t('password'),
  };

  const validation = {
    username: readyToValidation(fields.username, {
      required: true,
      minLength: 2,
      maxLength: 50,
    }),
    password: readyToValidation(fields.password, {
      required: true,
      minLength: 6,
      maxLength: 50,
    }),
  };

  return (
    <StyledMainBox>
      <Head>
        <title>OPS!YON - Login</title>
      </Head>
      <StyledOverlayImage />
      <Box sx={{ textAlign: 'center', zIndex: 2 }}>
        <StyledTypography>OPS!YON</StyledTypography>
        <StyledTypography>CUSTOMER MANAGEMENT</StyledTypography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '65%', margin: '0 auto' }}>
          <SyledFormGroup>
            <StyledFormControl
              fullWidth
              hiddenLabel
              {...register('userName', validation.username)}
              type="text"
              placeholder={fields.username}
              helperText={errors.userName?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle sx={{ color: '#fff' }} />
                  </InputAdornment>
                ),
              }}
            />
          </SyledFormGroup>
          <SyledFormGroup>
            <StyledFormControl
              type={passwordVisiblity ? 'text' : 'password'}
              fullWidth
              hiddenLabel
              {...register('password', validation.password)}
              placeholder={fields.password}
              helperText={errors.password?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock sx={{ color: '#fff' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordVisiblity ? (
                      <Visibility
                        onClick={handleVisibilityPassword}
                        sx={{ color: '#fff', cursor: 'pointer' }}
                      />
                    ) : (
                      <VisibilityOff
                        onClick={handleVisibilityPassword}
                        sx={{ color: '#fff', cursor: 'pointer' }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </SyledFormGroup>
          <Button
            type="submit"
            variant="contained"
            sx={{
              mt: 4,
              ...formControl,
              '&:hover': {
                bgcolor: '#0B1419',
              },
            }}
          >
            {t('login')}
          </Button>
        </form>
      </Box>
      <StyledCopyright>
        Copyright © Milvasoft Yazılım Bilişim Ar-Ge Dan. San. ve Tic. Ltd.ŞTİ.
      </StyledCopyright>
    </StyledMainBox>
  );
}

export const getServerSideProps = getLocalizationProps(['login', 'validation']);
