import { i18n } from 'next-i18next';
import { FieldName, FieldValues, RegisterOptions } from 'react-hook-form';

export default function readyToValidation(
  field: string,
  rules: RegisterOptions<FieldValues, FieldName<FieldValues>>
): RegisterOptions<FieldValues, FieldName<FieldValues>> {
  if (rules.required)
    rules.required = i18n?.t('required', { ns: 'validation', field }) as string;

  if (rules.minLength !== undefined) {
    rules.minLength = {
      value: parseInt(rules.minLength.toString(), 10),
      message: i18n?.t('minLength', {
        ns: 'validation',
        field,
        length: rules.minLength,
      }) as string,
    };
  }

  if (rules.maxLength !== undefined) {
    rules.maxLength = {
      value: parseInt(rules.maxLength.toString(), 10),
      message: i18n?.t('maxLength', {
        ns: 'validation',
        field,
        length: rules.maxLength,
      }) as string,
    };
  }

  if (rules.min !== undefined) {
    rules.min = {
      value: rules.min.toString(),
      message: i18n?.t('min', {
        ns: 'validation',
        field,
        value: rules.min,
      }) as string,
    };
  }

  if (rules.max !== undefined) {
    rules.max = {
      value: parseInt(rules.max.toString(), 10),
      message: i18n?.t('max', {
        ns: 'validation',
        field,
        value: rules.max,
      }) as string,
    };
  }

  if (rules.pattern !== undefined) {
    rules.pattern = {
      value: rules.pattern as unknown as RegExp,
      message:
        i18n?.t('pattern', {
          ns: 'validation',
          field,
        }) || '',
    };
  }

  return rules;
}
